import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import administrators from './administrators/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {sidebar, locale, cookieSettings, orderModal} from './settings/reducer'
import functions from './functions/reducer'
import references from './references/reducer'
import doctors from './doctors/reducer'
import tariffs from './tariffs/reducer'
import documents from './documents/reducer'
import orders from './orders/reducer'
import features from './features/reducer'
import pdfDocuments from './pdfDocuments/reducer'


import enums from './enums/reducer'
import frontend from './frontend/reducer'

export default combineReducers({
  toaster,
  auth,
  info,
  strings,
  item,
  sequence,
  administrators,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  sidebar,
  locale,
  cookieSettings,
  orderModal,
  functions,
  tariffs,
  doctors,
  references,
  documents,
  pdfDocuments,
  orders,
  features,
  frontend,
})